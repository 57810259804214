import React  from 'react';
import { NoteForm } from '../components/NoteForm';
// import { Header } from '../components/Header';

export default class AddNote extends React.Component {
    render() {
        return (
            <>
                <NoteForm />
            </>
        )

    }
}