import React from 'react';
import { WordsList } from '../components/WordsList';

export default class WordsPage extends React.Component {

    render() {
        return (
            <>
                <WordsList />
            </>
        )
    }
}