import React from 'react';
import { NavNoteThemes } from '../components/NavNoteThemes';

export const NotesPage = () => {

    return (
        <>
            <section className="section-main commonClass">
                <NavNoteThemes />
            </section>
        </>
    )
}