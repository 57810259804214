import React  from 'react';
// import { Header } from '../components/Header';
import { WordForm } from '../components/WordForm';

export default class AddWord extends React.Component {
    render() {
        return (
            <>
                <WordForm />
            </>
        )

    }
}