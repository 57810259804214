import React  from 'react';
// import { Header } from '../components/Header';
import { CategoryForm } from '../components/CategoryForm';

export default class CategoryPage extends React.Component {
    render() {
        return (
            <>
                <CategoryForm />
            </>
        )

    }
}